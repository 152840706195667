/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app1.scss';

// start the Stimulus application
import './bootstrap';

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

 $(document).ready(()=>{

     // Gestion de l'agrandissement des photos dans la gallerie

     let allGridPhotos = $('.gallery_picture');
     let popupBg = $('#bg-gallery');
     let popupImg = $('#popup-img')
 
     allGridPhotos.click((e) => {
         let photoClicked = $(this).closest('.gallery_picture');
         popupBg.addClass("active");
         let photoId = e.target.id;
         let source = `/gallery/${photoId}`
         popupImg.attr('src', source)
     })
 
     popupImg.click((e) => {
         e.stopPropagation()
     })
 
     $('.popup-close').click(() => {
         popupBg.removeClass("active");
     })
 
     popupBg.click(() => {
         popupBg.removeClass("active");
     })

     // Tabs produit Stop Evap
     
     let dimension = $('#dimensions_stop');
     let carac = $('#caracteristiques_stop');
     let condi = $('#condi_stop');

     $('.dimension_btn').click(() => {
        $('.dimension_btn').addClass('active');
        $('.carac_btn').removeClass('active');
        $('.condi_btn').removeClass('active');
        dimension.css('display', 'block');
        carac.css('display', 'none');
        condi.css('display', 'none');
     })
     
     $('.carac_btn').click(() => {
        $('.carac_btn').addClass('active');
        $('.dimension_btn').removeClass('active');
        $('.condi_btn').removeClass('active');
        carac.css('display', 'block');
        dimension.css('display', 'none');
        condi.css('display', 'none');
     })

     $('.condi_btn').click(() => {
        $('.condi_btn').addClass('active');
        $('.carac_btn').removeClass('active');
        $('.dimension_btn').removeClass('active');
        condi.css('display', 'block');
        carac.css('display', 'none');
        dimension.css('display', 'none');
     })

     
     
    



 })